
import Vue from 'vue';
import Component from 'vue-class-component';
import { Ref, Prop } from 'vue-property-decorator';
import contentModule from '@/store/modules/content.module';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import SubscriptionPanel from '@/modules/organisation/components/SubscriptionPanel.vue';
import { Loading } from '@/modules/auth/store/auth.service';
import * as _ from 'lodash';
import { Auth } from '../../../store/modules/auth.module';
import SignUpButton from '@/modules/marketing/pricing/SignUpButton.vue';
import SubscribeButton from '@/modules/marketing/pricing/SubscribeButton.vue';
import UpgradeButton from '@/modules/marketing/pricing/UpgradeButton.vue';

@Component({
  components: {
    SubscriptionPanel,
    SignUpButton,
    SubscribeButton,
    UpgradeButton,
  },
})
export default class OrganisationPaymentModal extends Vue {
  @Prop() isActive!: boolean;
  @Prop({ default: false }) isMarketing!: boolean;
  @Ref('paymentForm') readonly paymentForm: HTMLElement;
  activeIndex = 0;
  selected = true;
  loading = false;
  pricingOrder = ['Free Trial', 'basic', 'standard', 'premium', 'enterprise'];
  get content() {
    const contentArr: any[] = contentModule.subContent.filter((sub: any) => sub.fields.price !== 0);
    let priceArr: any[] = contentArr.sort((a, b) => {
      return this.pricingOrder.indexOf(a.fields.name.toLowerCase()) - this.pricingOrder.indexOf(b.fields.name.toLowerCase());
    });
    return priceArr;
  }

  get small() {
    if (this.content && this.content.length > 0) return this.content.find((val) => val?.fields?.name === 'Small').fields;
    return 0;
  }
  get medium() {
    if (this.content && this.content.length > 0) return this.content.find((val) => val?.fields?.name === 'Medium').fields;
    return 0;
  }
  get large() {
    if (this.content && this.content.length > 0) return this.content.find((val) => val?.fields?.name === 'Large').fields;
    return 0;
  }

  beforeDestroy() {
    this.$emit('update:isActive', false);
  }
  destroyed() {
    this.$emit('update:isActive', false);
  }
  get paymentData() {
    return tenantModule.paymentData;
  }
  pop() {
    this.$emit('update:isActive', false);
  }
  toggle(index: number) {
    this.activeIndex = index;
  }

  async signIn() {
    await this.$msal.signIn({ state: 'register' });
  }

  async signUp(plan: string) {
    if (!Auth.isAuthenticated) {
      await this.$msal.signIn({ state: 'register' });
    } else {
      const response = await this.$swal({
        icon: 'info',
        title: 'Confirm ' + plan + ' plan',
        text: 'To complete you registration please click OK to go to Payfast and complete your payment',
        position: 'center',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        showConfirmButton: true,
        confirmButtonText: 'OK',
      });

      // if (response.isConfirmed) {
      //   this.getPaymentData(plan);
      // }
    }
  }

  done = false;
}
