




import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SignUpButton extends Vue {
  async signIn(state: string) {
    await this.$msal.signIn({ state });
  }
}
