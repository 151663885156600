
import Vue from 'vue';
import Component from 'vue-class-component';
import { Prop, PropSync, Ref } from 'vue-property-decorator';
import SubscriptionDetails from '@/modules/organisation/components/SubscriptionDetails.vue';
import { Loading } from '@/modules/auth/store/auth.service';
import dayjs from 'dayjs';
import { tenantModule } from '@/store/modules/tenant/tenant.module';
import OrganisationPaymentModal from './Pricing.vue';

@Component({
  components: {
    SubscriptionDetails,
    OrganisationPaymentModal,
  },
})
export default class SubscriptionPanel extends Vue {
  @Prop() subData!: any;

  @PropSync('isActive') activeSync!: boolean;

  @Ref('paymentForm') readonly paymentForm: any;

  get fields() {
    return this.subData.fields;
  }

  activeChange() {
    this.activeSync = !this.activeSync;
  }

  get paymentData() {
    return tenantModule.paymentData;
  }

  get subscription() {
    return tenantModule.tenant.subscription;
  }

  get plan() {
    return this.subscription?.plan;
  }

  getDate(date: any) {
    return dayjs(date).format('MMM D,YYYY');
  }

  @Loading
  async getPaymentData() {
    await tenantModule.getPaymentData(tenantModule.tenant.subscription?.id as string);
  }
}
