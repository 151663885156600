




import { Component, Prop, Vue } from 'vue-property-decorator';
import { tenantModule } from '../../../store/modules/tenant/tenant.module';
import { Loading } from '@/modules/auth/store/auth.service';

@Component
export default class SubscribeButton extends Vue {
  @Prop() planName: string;

  @Loading
  async getPaymentData(subscriptionType: string): Promise<void> {
    this.$emit('update');

    this.$router.push({ path: '/organisation/account/checkout', query: { plan: subscriptionType } });

    //   await tenantModule.getPaymentData(subscriptionType);

    //  const clickEvent = new Event('click'); // Create the event.
    //   const submit = document.getElementById('form-submit');
    //   if (submit) {
    //     submit.click();
    //   }
    //
  }
}
